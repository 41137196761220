<!-- Hero Start -->
<section class="section mt-60">      
  <div class="container mt-lg-3">        
     
     
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h1 class="title">Cookie Policy</h1>
          <h6>Ai sensi dell'art. 13 del D.Lgs. n. 196/2003 Codice in materia di protezione dei dati personali e degli articoli 13 e 14 del GDPR 2016/679.</h6>
          <ul class="list-unstyled mt-4">
            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Ultima Revisione:</span> 14 febbraio 2023</li>
          </ul>
          <!-- <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Utility</a></li>
                <li class="breadcrumb-item active" aria-current="page">Privacy</li>
              </ul>
            </nav>
          </div> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow rounded border-0">
          <div class="card-body">
             <h5 class="card-title">Introduzione</h5>
            <p class="text-muted">Questo sito web, https://www.pl24.it (di seguito: "il sito web") utilizza i cookie e altre tecnologie correlate (per comodità tutte le tecnologie sono definite "cookie"). I cookie possono essere anche inseriti da software di terze parti. Nel documento sottostante ti informiamo sull'uso dei cookie sul nostro sito web.</p>
            
            <h5 class="card-title">Cosa sono i Cookie ?</h5>
            <p class="text-muted">I cookie sono dei semplici file spediti assieme alle pagine di questo sito e salvati dal tuo browser sul disco rigido del tuo computer o altri dispositivi. Le informazioni raccolte in essi possono venire rispediti ai nostri server oppure ai server di terze parti durante la prossima visita.</p>
      
            <h5 class="card-title">Cosa sono gli Script ?</h5>
            <p class="text-muted">Uno script è un pezzo di codice usato per far funzionare correttamente ed interattivamente questo sito web.</p>
      
            <h5 class="card-title">Cosa sono un Web Beacon ?</h5>
            <p class="text-muted">Un web beacon (o pixel tag) è un piccolo, invisibile pezzo di testo o immagine su un sito che viene usato per monitorare il traffico di un sito web. Per fare questo, diversi dati su di te vengono conservati utilizzando dei web beacon.</p>
            
            <h5 class="card-title">Come si distinguono i Cookie ?</h5>
            <ul class="list-unstyled text-muted">
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                <b>Cookie tecnici o funzionali</b>
                <p class="text-muted">Alcuni cookie assicurano il corretto funzionamento del sito e che le tue preferenze rimangano valide. Piazzando cookie funzionali, si rende più facile visitare un sito web. In questo modo ad esempio non bisogna inserire ripetutamente le stesse informazioni.</p>
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                <b>Cookie statistici</b>
                <p class="text-muted">I cookie statistici servono per ottenere approfondimenti sull'uso di un sito web.</p>
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                <b>Cookie pubblicitari</b>
                <p class="text-muted">I cookie pubblicitari permettono di ottenere informazioni sui risultati delle campagne pubblicitarie. Con questi cookie, in qualità di visitatore di un sito web, si è associati ad un ID univoco, ma tali cookie non profilano il comportamento e/o gli interessi personali.</p>
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                <b>Cookie di Marketing/Tracciamento</b>
                <p class="text-muted">I cookie di Marketing/Tracciamento vengono utilizzati per creare profili utente per la visualizzazione di pubblicità o per tracciare l'utente ai fini di marketing.</p>
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                <b>Cookie dei Social Media</b>
                <p class="text-muted">I cookie dei Social Media servono per promuovere pagine web o azioni (ad es. "mi piace", "pin") su social network come Facebook, WhatsApp e Instagram. Questi cookie sono incorporati con codice derivato da Facebook, WhatsApp, Instagram, ecc. Questo contenuto potrebbe memorizzare ed elaborare alcune informazioni per la pubblicità personalizzata.<br>Tuttavia, si consiglia di leggere sempre le informative sulla privacy di questi social network (che possono cambiare regolarmente).</p>
              </li>              
            </ul>
            <h5 class="card-title">Cookie presenti su questo sito</h5>
            <ul class="list-unstyled text-muted">
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                <b>Cookie tecnici o funzionali</b>
                <p class="text-muted">L'unico cookie attivo su questo sito web è di tipo "tecnico-funzionale" ed è denominato "cookie_consent_level". Esso permette appunto la gestione del consenso o del diniego dei cookie stessi.</p>
              </li>
            </ul>  
           <h5 class="card-title">Consenso</h5>
            <p class="text-muted">Quando visiti questo sito web per la prima volta, noi mostreremo un popup con una spiegazione dei cookie. Appena clicchi su "Salva le mie impostazioni", dai il consenso ad usare le categorie di cookie come descritto in questa dichiarazione relativa ai popup e cookie. Puoi disabilitare i cookie attraverso il tuo browser, ma prendi in considerazione, che questo sito web potrebbe non funzionare più correttamente.</p>
                       
            <h5 class="card-title">Abilitare/disabilitare e cancellazione cookie</h5>
            <p class="text-muted">Puoi usare il tuo browser per cancellare automaticamente o manualmente i cookie. È anche possibile specificare che determinati cookie non possono essere piazzati. Un'altra opzione è quella di modificare le impostazioni del tuo browser internet in modo da ricevere un messaggio ogni volta che viene inserito un cookie. Per ulteriori informazioni su queste opzioni, consultare le istruzioni nella sezione Guida del tuo browser.<br>Ricorda che questo sito potrebbe non funzionare correttamente se tutti i cookie sono disabilitati. Se cancelli i cookie nel tuo browser, saranno riposti nuovamente dopo il tuo permesso quando visiterai nuovamente questo sito web.</p>
<!-- 
            <h5 class="card-title">I tuoi diritti e il rispetto dei dati personali</h5>
            <ul class="list-unstyled text-muted">
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Hai il diritto di sapere quando i tuoi dati personali sono necessari, cosa succede ad essi, quanto a lungo verranno mantenuti.                
              </li> 
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Diritto di accesso: hai il diritto ad accedere ai tuoi dati personali dei quali siamo a conoscenza.                
              </li>      
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Diritto di rettifica: hai il diritto di completare, correggere, cancellare o bloccare i tuoi dati personali quando lo desideri.               
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Se ci darai il consenso per elaborare i tuoi dati, hai il diritto di revocare questo consenso e di eliminare i tuoi dati personali.                
              </li>     
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Diritto di obiezione: hai il diritto di obiezione verso il processo dei tuoi dati. Noi rispetteremo questa scelta, a meno che non ci siano delle basi valide per il processo.               
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Se ci darai il consenso per elaborare i tuoi dati, hai il diritto di revocare questo consenso e di eliminare i tuoi dati personali.                
              </li>                             
            </ul>  
            <p class="text-muted">Per esercitare questi diritti, non esitate a contattarci. Si prega di fare riferimento ai dettagli di contatto in fondo a questa Cookie Policy. Se hai un reclamo su come gestiamo i tuoi dati, vorremmo sentirti, ma hai anche il diritto di presentare un reclamo all'autorità di vigilanza (l'Autorità per la Protezione dei Dati).</p>
            
            <h5 class="card-title">Dettagli Contatti</h5>
            <p class="text-muted">Per domande e/o commenti riguardo la Cookie Policy e questa dichiarazione, puoi contattarci usando i seguenti dati:<br><br>
              ITALSET SRL<br>
              VIA VECCHIA MATINO, 20<br>
              73042 CASARANO (LE)<br>
              ITALY<br>             
              Sito web: https://www.italset.it<br>
              Email: info@italset.it<br>
              Numero di telefono: 0833505393</p>
-->
             <br><br>
             <a href="javascript:window.print()" class="btn btn-soft-primary d-print-none">Stampa questa Pagina</a> 
           
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->