<!-- Hero Start -->
<section class="section mt-60">      
    <div class="container mt-lg-3">        
       
        <div class="row">
            <div class="col-lg-4 col-md-6 col-12 d-lg-block d-none">
              <div class="sidebar sticky-bar p-4 rounded shadow">
                <div class="widget">
                  <h5 class="widget-title">Accertamento: <strong>{{infrazione?.serie}} {{infrazione?.bolletta}}/{{infrazione?.anno}}</strong></h5>
                  <h5 class="widget-title">Reg. Cronol.: <strong>{{infrazione?.cronologico}}/{{infrazione?.anno}}</strong></h5>
                  <!-- <div class="row mt-4">
                    <div class="col-6 text-center">
                      <i-feather name="user-plus" class="fea icon-ex-md text-primary mb-1"></i-feather>
                      <h5 class="mb-0">2588</h5>
                      <p class="text-muted mb-0">Followers</p>
                    </div> -->
                    <!--end col-->
      
                    <!-- <div class="col-6 text-center">
                      <i-feather name="users" class="fea icon-ex-md text-primary mb-1"></i-feather>
                      <h5 class="mb-0">454</h5>
                      <p class="text-muted mb-0">Following</p>
                    </div> -->
                    <!--end col-->
                  <!-- </div> -->
                  <!--end row-->
                </div>
      
                <!-- <div class="widget mt-4 pt-2">
                  <h5 class="widget-title">Projects :</h5> -->
                  <!-- <div class="progress-box mt-4">
                    <h6 class="title text-muted">Progress</h6>
                    <div class="progress">
                      <div class="progress-bar position-relative bg-primary" style="width:50%;">
                        <div class="progress-value d-block text-muted h6">24 / 48</div>
                      </div>
                    </div>
                  </div> -->
                  <!--end process box-->
                <!-- </div> -->
      
                <div class="widget mt-4">
                  <ul class="list-unstyled sidebar-nav mb-0" id="navmenu-nav">
                    <li class="navbar-item account-menu px-0 active">
                      <a routerLink="#" class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                        <span class="h4 mb-0"><i class="uil uil-dashboard"></i></span>
                        <h6 class="mb-0 ms-2">Informazioni Generali</h6>
                      </a>
                    </li>
      
                    <li class="navbar-item account-menu px-0 mt-2 active">
                      <a href="{{linkPayment}}" target="_blank" class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                        <span class="h4 mb-0"><i class="uil uil-bill"></i></span>
                        <h6 class="mb-0 ms-2">PAGA ORA</h6>
                      </a>
                    </li>

                    <!--
      
                    <li class="navbar-item account-menu px-0 mt-2">
                      <a routerLink="/account-works" class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                        <span class="h4 mb-0"><i class="uil uil-file"></i></span>
                        <h6 class="mb-0 ms-2">Portfolio</h6>
                      </a>
                    </li>
      
                    <li class="navbar-item account-menu px-0 mt-2">
                      <a routerLink="/account-messages"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                        <span class="h4 mb-0"><i class="uil uil-envelope-star"></i></span>
                        <h6 class="mb-0 ms-2">Messages</h6>
                      </a>
                    </li>
      
                    <li class="navbar-item account-menu px-0 mt-2">
                      <a routerLink="/account-payments"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                        <span class="h4 mb-0"><i class="uil uil-transaction"></i></span>
                        <h6 class="mb-0 ms-2">Payments</h6>
                      </a>
                    </li>
      
                    <li class="navbar-item account-menu px-0 mt-2">
                      <a routerLink="/account-setting" class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                        <span class="h4 mb-0"><i class="uil uil-setting"></i></span>
                        <h6 class="mb-0 ms-2">Settings</h6>
                      </a>
                    </li>
      
                    <li class="navbar-item account-menu px-0 mt-2">
                      <a routerLink="/auth-login-three"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                        <span class="h4 mb-0"><i class="uil uil-dashboard"></i></span>
                        <h6 class="mb-0 ms-2">Logout</h6>
                      </a>
                    </li> -->
                  </ul>
                </div>
      
                <!-- <div class="widget mt-4 pt-2">
                  <h5 class="widget-title">Follow me :</h5>
                  <ul class="list-unstyled social-icon mb-0 mt-4">
                    <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="github" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="youtube" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="gitlab" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                  </ul> -->
                  <!--end icon-->
                <!-- </div> -->
              </div>
            </div>
            <!--end col-->
      
            <div class="col-lg-8 col-12">
              <div class="border-bottom pb-4">
                <h4>Consultazione Accertamento</h4>
                <p class="text-muted mb-0">In questa pagina troverai le informazioni più importanti dell'accertamento richiesto.</p>
              </div>
      
              <div class="border-bottom pb-4">
                <div class="row">
                  <div class="col-md-12 mt-4">
                    <h5>Informazioni Generali:</h5>
                    <div class="mt-4">
                      <div class="d-flex align-items-center">
                        <i-feather name="grid" class="fea icon-ex-md text-muted me-3"></i-feather>
                        <div class="flex-1">
                          <h6 class="text-primary mb-0">Tipologia Infrazione:</h6>
                          <a href="javascript:void(0)" class="text-muted">{{infrazione?.desTipoAccertamento}}</a>
                        </div>
                      </div>
                      <div class="d-flex align-items-center mt-3">
                        <i-feather name="calendar" class="fea icon-ex-md text-muted me-3"></i-feather>
                        <div class="flex-1">
                          <h6 class="text-primary mb-0">Data e Ora Infrazione:</h6>
                          <a href="javascript:void(0)" class="text-muted">{{infrazione?.dataInfrazione}} - {{infrazione?.oraInfrazione}}</a>                          
                        </div>
                      </div>
                      
                      <div class="d-flex align-items-center mt-3">
                        <i-feather name="map-pin" class="fea icon-ex-md text-muted me-3"></i-feather>
                        <div class="flex-1">
                          <h6 class="text-primary mb-0">Località Infrazione:</h6>
                          <a href="javascript:void(0)" class="text-muted">{{infrazione?.localitaInfrazione}}</a>
                        </div>
                      </div>
                      <div class="d-flex align-items-center mt-3">
                        <i-feather name="airplay" class="fea icon-ex-md text-muted me-3"></i-feather>
                        <div class="flex-1">
                          <h6 class="text-primary mb-0">Veicolo:</h6>
                          <a href="javascript:void(0)" class="text-muted">{{infrazione?.tipoVeicolo}} {{infrazione?.modelloVeicolo}} targato {{infrazione?.targaVeicolo}}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
      
                <!-- <div class="col-md-6 mt-4 pt-2 pt-sm-0"> -->
                    <!-- <h5>Experience :</h5> -->
      
                    <!-- <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-4">
                      <img src="assets/images/job/Circleci.svg" class="avatar avatar-ex-sm" alt="">
                      <div class="flex-1 content ms-3">
                        <h4 class="title mb-0">Senior Web Developer</h4>
                        <p class="text-muted mb-0">3 Years Experience</p>
                        <p class="text-muted mb-0"><a href="javascript:void(0)" class="text-primary">CircleCi</a> @London, UK
                        </p>
                      </div>
                    </div> -->
      
                    <!-- <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-4">
                      <img src="assets/images/job/Codepen.svg" class="avatar avatar-ex-sm" alt="">
                      <div class="flex-1 content ms-3">
                        <h4 class="title mb-0">Web Designer</h4>
                        <p class="text-muted mb-0">2 Years Experience</p>
                        <p class="text-muted mb-0"><a href="javascript:void(0)" class="text-primary">Codepen</a> @Washington
                          D.C, USA</p>
                      </div>
                    </div>
       -->
                    <!-- <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-4">
                      <img src="assets/images/job/Gitlab.svg" class="avatar avatar-ex-sm" alt="">
                      <div class="flex-1 content ms-3">
                        <h4 class="title mb-0">UI Designer</h4>
                        <p class="text-muted mb-0">2 Years Experience</p>
                        <p class="text-muted mb-0"><a href="javascript:void(0)" class="text-primary">Gitlab</a> @Perth,
                          Australia</p>
                      </div>
                    </div> -->
                <!-- </div> -->
                  <!--end col-->
                </div>
                <!--end row-->
              </div>
      
              <h5 class="mt-4 mb-0">Documentazioni Fotografiche:</h5>
              <div class="row">
                <div class="col-md-6 mt-4 pt-2">
                  <div class="card blog rounded border-0 shadow" (click)="openImage(1)">
                    <div class="position-relative">
                      <img [src]="image1Rilevazione" class="card-img-top rounded-top" alt="" >
                      <div class="overlay rounded-top bg-dark"></div>
                    </div>
                    <div class="card-body content">
                      <h5><a href="javascript:void(0)" class="card-title title text-dark">Immagine 1 (Click per aprire)</a></h5>
                      <!-- <div class="post-meta d-flex justify-content-between mt-3">
                        <ul class="list-unstyled mb-0">
                          <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i
                                class="uil uil-heart me-1"></i>33</a></li>
                          <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                                class="uil uil-comment me-1"></i>08</a></li>
                        </ul>
                        <a routerLink="/page-blog-detail" class="text-muted readmore">Read More <i
                            class="uil uil-angle-right-b align-middle"></i></a>
                      </div> -->
                    </div>
                    <!-- <div class="author">
                      <small class="text-light user d-block"><i class="uil uil-user"></i> Calvin Carlo</small>
                      <small class="text-light date"><i class="uil uil-calendar-alt"></i> 13th August, 2019</small>
                    </div> -->
                  </div>
                </div>
                <!--end col-->
      
                <div *ngIf="flgFoto2==true" class="col-md-6 mt-4 pt-2">
                  <div class="card blog rounded border-0 shadow" (click)="openImage(2)">
                    <div class="position-relative">
                      <img [src]="image2Rilevazione" class="card-img-top rounded-top" alt="" >
                      <div class="overlay rounded-top bg-dark"></div>
                    </div>
                    <div  class="card-body content">
                      <h5><a href="javascript:void(0)" class="card-title title text-dark">Immagine 2 (Click per aprire)</a></h5>
                      <!-- <div class="post-meta d-flex justify-content-between mt-3">
                        <ul class="list-unstyled mb-0">
                          <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i
                                class="uil uil-heart me-1"></i>33</a></li>
                          <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                                class="uil uil-comment me-1"></i>08</a></li>
                        </ul>
                        <a routerLink="/page-blog-detail" class="text-muted readmore">Read More <i
                            class="uil uil-angle-right-b align-middle"></i></a>
                      </div> -->
                    </div>
                    <!-- <div class="author">
                      <small class="text-light user d-block"><i class="uil uil-user"></i> Calvin Carlo</small>
                      <small class="text-light date"><i class="uil uil-calendar-alt"></i> 13th August, 2019</small>
                    </div> -->
                  </div>
                </div>
                <!--end col-->
                
      
                <!-- <div class="col-12 mt-4 pt-2">
                  <a routerLink="/page-blog-grid" class="btn btn-primary">See More <i
                      class="uil uil-angle-right-b align-middle"></i></a>
                </div> -->
                <!--end col-->
              </div>
              <!--end row-->
            </div>
            <!--end col-->
          </div>
          <!--end row-->  
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Hero End -->
  
  
  
  
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->
