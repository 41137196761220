import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Infrazione } from '../../models/common';
import { EnumLocalStorage } from '../../models/enum';
import { InfrazioneService } from '../../services/infrazione.service';

@Component({
  selector: 'app-infrazione',
  templateUrl: 'infrazione.component.html',
  styleUrls: ['infrazione.component.css']
})
export class InfrazioneComponent implements OnInit {

  constructor(
              private infrazioneService: InfrazioneService,
              private router: Router,
              private datepipe: DatePipe,
              private sanitizer: DomSanitizer                  
              ) { }

  infrazione: Infrazione | undefined;
  image1Rilevazione: SafeResourceUrl = '';
  image2Rilevazione: SafeResourceUrl = '';
  flgFoto1: boolean=true;
  flgFoto2: boolean=true;

  linkPayment: string='';
  codEnte: string | null = '';
  datiEnteSub: Subscription = Subscription.EMPTY;
  
  ngOnInit(): void {

    this.infrazione = this.infrazioneService.getLocalInfrazione();
    if (!this.infrazione) {
      this.router.navigateByUrl('/');
    }
    else {
      // gestione tipo accertamento
      switch(this.infrazione.tipoAccertamento) { 
        case "V": { 
           this.infrazione.desTipoAccertamento = "SUPERAMENTO LIMITI DI VELOCITA'";
           break; 
        } 
        case "P": { 
          this.infrazione.desTipoAccertamento = "PASSAGGIO CON SEMAFORO ROSSO";
           break; 
        } 
        case "O": { 
          this.infrazione.desTipoAccertamento = "ORDINANZA";
           break; 
        }
        case "T": { 
          this.infrazione.desTipoAccertamento = "VERIFICA COPERTURA ASSICURATIVA / REVISIONE VEICOLO";
           break; 
        } 
        default: { 
           this.infrazione.desTipoAccertamento = "GENERICO";
           break; 
        } 
      } 
      
      // conversione data infrazione
      this.infrazione.dataInfrazione = this.datepipe.transform(this.infrazione.dataInfrazione, 'dd/MM/yyyy');

      // decodifica immagine rilevazione 1
      this.image1Rilevazione = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.infrazione.img1Base64Encoded}`);
      if(this.infrazione.img1Infrazione!=null) {
        this.flgFoto1=true;
      }
      // decodifica immagine rilevazione 2
      this.image2Rilevazione = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.infrazione.img2Base64Encoded}`);
      if(this.infrazione.img2Infrazione!=null) {
        this.flgFoto2=true;        
      }
      //alert(this.infrazione.img1Infrazione + " " + this.infrazione.img2Infrazione);
      // alert(this.infrazione.img1Infrazione);
      // alert(this.infrazione.img2Infrazione);

      this.codEnte = localStorage.getItem(EnumLocalStorage.CodEnte).toLowerCase();  // recupero dalla variabile locale il codice ente

      this.linkPayment = "https://pagopa.italset.it/?id_ec=c_" + this.codEnte;
    }
  }

  openImage(numImage: number) {
        
    var image = new Image();

    if(numImage===1) {
      if(this.infrazione.img1Base64Encoded === null) {
        return;
      }

      image.src = "data:image/jpg;base64," + this.infrazione.img1Base64Encoded;
    }
    if(numImage===2) {
      if(this.infrazione.img2Base64Encoded === null) {
        return;
      }
      image.src = "data:image/jpg;base64," + this.infrazione.img2Base64Encoded;
    }

    var params = [
      'height='+(screen.height-50), 'width='+(screen.width-150),
      'resizable=yes, scrollbars=yes, location=0, directories=0, status=no, menubar=no, toolbar=no'      
    ].join(',');
    
    var w = window.open("Zoom Image", "_blank", params);   
    //w.document.write(""); 
    w.document.write("<h3>"+this.infrazione.desEnte+" - POLIZIA LOCALE</h3><h2>IMMAGINE INFRAZIONE: "+ this.infrazione.desTipoAccertamento + " Verb.: " + this.infrazione.serie + " " + this.infrazione.bolletta + "/" + this.infrazione.anno + "</h2><h5>(Per effettuare la stampa della foto click destro del mouse + Stampa)</h5>"+image.outerHTML);
    //w.document.close();
    w.moveTo(0,0);
    w.focus();
    //w.print();
    //w.close();        
    //w.print(), 0;       
  }  

}
