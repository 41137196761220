import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Infrazione } from 'src/app/pl24/models/common';
import { InfrazioneService } from 'src/app/pl24/services/infrazione.service';

@Component({
  selector: 'app-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.css']
})

/**
 * Account Profile Component
 */
export class AccountProfileComponent implements OnInit {

  /**
   * nav light class add
   */
  navClass = 'nav-light';

  constructor(
              private infrazioneService: InfrazioneService,
              private router: Router
              ) { }

  infrazione: Infrazione | undefined;

  ngOnInit(): void {
    this.infrazione = this.infrazioneService.getLocalInfrazione();
    if (!this.infrazione) {
      this.router.navigateByUrl('/');
    }
  }


}
