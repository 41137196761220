<!-- Hero Start -->
<section class="bg-home d-flex align-items-center">      
  <div class="container">        
    <div class="row align-items-center">          
      <div class="col-lg-7 col-md-6">
        <div class="me-lg-5">
          <img src="assets/images/user/login.svg" class="img-fluid d-block mx-auto" alt="">            
        </div>
      </div>        
      <div class="col-lg-5 col-md-6">
        <div class="card login-page bg-white shadow rounded border-0">
          <div class="card-body">
            <h4 class="card-title text-center">Cerca Infrazione</h4>
            <div *ngIf="isInfrazioneFormValid === false" class="row">
              <div class="col-12 text-center" style="color:tomato;">
                {{errorMessage}}
              </div>
            </div>
            <form [formGroup]="infrazioneForm" class="login-form mt-4">
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Targa / Plate Number <span class="text-danger">*</span></label><a href="javascript:void(0)" (click)="openModal(ForeignPlateHelp)"> <span class="text-primary form-label"> Foreign Plate Help</span></a>
                    <div class="form-icon position-relative">
                      <i-feather name="credit-card" class="fea icon-sm icons"></i-feather>
                      <input type="text" formControlName="targa" class="form-control ps-5" placeholder="" name="targa" required="true">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">N° Accertamento (Usare solo numero verbale, es. "12584") <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="file-text" class="fea icon-sm icons"></i-feather>
                      <input type="text" formControlName="accertamento" class="form-control ps-5" placeholder="" name="accertamento" required="true">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Data Infrazione <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="calendar" class="fea icon-sm icons"></i-feather>
                      <input type="date" formControlName="data" class="form-control ps-5" placeholder="" name="data" required="true">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Password <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="key" class="fea icon-sm icons"></i-feather>
                      <input type="password" formControlName="password" class="form-control ps-5" placeholder="" name="password" required="true">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-12">
                  <div class="d-flex justify-content-between">
                    <div class="mb-3">
                      <div class="form-check">
                        <!-- <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">Remember me</label> -->
                      </div>
                    </div>
                    <!-- <p class="forgot-pass mb-0"><a routerLink="/auth-re-password" class="text-dark fw-bold">Forgot password ?</a></p> -->
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-12 mb-0">
                  <div class="d-grid">
                    <button class="btn btn-primary" (click)="onSubmit()">Avvia Ricerca</button>
                  </div>                  
                </div>
                <!--end col-->

                <!-- <div class="col-lg-12 mt-4 text-center">
                  <h6>Or Login With</h6>
                  <div class="row">
                    <div class="col-6 mt-3">
                      <div class="d-grid">
                        <a href="javascript:void(0)" class="btn btn-light"><i class="mdi mdi-facebook text-primary"></i>
                          Facebook</a>
                      </div>
                    </div> -->
                    <!--end col-->

                    <!-- <div class="col-6 mt-3">
                      <div class="d-grid">
                        <a href="javascript:void(0)" class="btn btn-light"><i class="mdi mdi-google text-danger"></i>
                          Google</a>
                      </div>
                    </div> -->
                    <!--end col-->
                  <!-- </div>
                </div> -->
                <!--end col-->

                <!-- <div class="col-12 text-center">
                  <p class="mb-0 mt-3"><small class="text-dark me-2">Don't have an account ?</small> <a
                      routerLink="/auth-signup" class="text-dark fw-bold">Sign Up</a></p>
                </div> -->
                <!--end col-->
              </div>
              <!--end row-->
            </form>
            
          </div>            
        </div>
        <!---->
         
      </div>
      
      <!--end col-->
    </div>
    <!--end row-->      
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Modal Content Start -->
<ng-template #ForeignPlateHelp let-modal>
  <div class="modal-content rounded shadow border-0">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalCenterTitle">Foreign Plate Help</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body p-4">
      <h6 class="mt-2">English</h6>
                <p class="text-muted">For a correct display of the photograph, enter the number of the report without letters and the number plate in this format:<br>STATUS SIGN - PLATE NUMBER (<span class="text-danger">SI-XXXXX, HR-XXXXX, HU-XXXXX</span>). 
                  <!-- <a href="https://www.flaticon.com/packs/basic-icon" target="_blank"><code
                      class="text-primary">https://www.flaticon.com/packs/basic-icon</code></a> -->
                    </p>

                <!-- <img src="assets/images/icon/Email.svg" height="50" class="me-3" alt="">
                <img src="assets/images/icon/bitcoin.svg" height="50" class="me-3" alt="">
                <img src="assets/images/icon/calendar.svg" height="50" class="me-3" alt="">
                <img src="assets/images/icon/location.svg" height="50" class="me-3" alt=""> -->

                <h6 class="mt-4">German</h6>
                <p class="text-muted">Für eine korrekte Anzeige des Fotos, geben Sie die Nummer des Berichts ohne Buchstaben und das Nummernschild in diesem Format:<br>STAAT - PROVINZ ZEICHEN * NUMMER DES PLATZES (<span class="text-danger">DE-M*XXXXX, AT-BM*XXXXX</span>)</p>
                <!-- <h6 class="text-muted mb-4 pb-2">Ex. <code
                    class="text-danger"><br>&lt;span class="h3 text-primary me-2"&gt;<br>&nbsp; &lt;i class="uil uil-0-plus"&gt;&lt;/i&gt;<br>&lt;/span&gt;</code>
                </h6> -->
    </div>
  </div>
</ng-template>
<!-- Modal Content End -->




<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->