<!-- Hero Start -->
<section class="section mt-60">      
  <div class="container mt-lg-3">        
     
     
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h1 class="title">Privacy Policy</h1>
          <h6>Ai sensi dell'art. 13 del D.Lgs. n. 196/2003 Codice in materia di protezione dei dati personali e degli articoli 13 e 14 del GDPR 2016/679.</h6>
          <ul class="list-unstyled mt-4">
            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Ultima Revisione:</span> 14 febbraio 2023</li>
          </ul>
          <!-- <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Utility</a></li>
                <li class="breadcrumb-item active" aria-current="page">Privacy</li>
              </ul>
            </nav>
          </div> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow rounded border-0">
          <div class="card-body">            
            <h5 class="card-title">Tipologia dei dati trattati</h5>
            <ul class="list-unstyled text-muted">
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                <b>Dati di Navigazione</b>
                <p class="text-muted">I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet.<br>
                  Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni, permettere di identificare gli utenti.<br>                  
                  In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all'ambiente informatico dell'utente.<br>                  
                  I dati potrebbero essere utilizzati per l'accertamento di responsabilità in caso di ipotetici reati informatici ai danni del sito: salva questa eventualità, i dati saranno conservati per un periodo di tempo non superiore a quello necessario agli scopi per i quali essi sono stati raccolti.</p>
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                <b>Dati forniti volontariamente dall'utente</b>
                <p class="text-muted">L'invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati nei differenti canali di accesso di questo sito e la compilazione dei “moduli” (maschere/form) specificamente predisposti comportano la successiva acquisizione dell'indirizzo e dei dati del mittente/utente, necessari per rispondere alle richieste effettuate e per erogare i servizi richiesti.<br>
                  Specifiche informative di sintesi verranno progressivamente riportate o visualizzate nelle pagine del sito predisposte per particolari servizi a richiesta.</p>
              </li>
            </ul> 
            <h5 class="card-title">Finalità del trattamento</h5>
            <ul class="list-unstyled text-muted">
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Finalità strettamente connesse e necessarie alla fruizione del Sito e dei Servizi richiesti all'Ente.                
              </li>                        
            </ul>

            <h5 class="card-title">Modalità di trattamento dei dati</h5>
            <p class="text-muted">I dati personali sono trattati con strumenti automatizzati per il tempo strettamente necessario a conseguire gli scopi per cui sono raccolti.</p>
            
            <h5 class="card-title">Facoltatività del trattamento</h5>
            <p class="text-muted">Il conferimento dei dati personali è facoltativo. L'eventuale rifiuto di conferirli può comportare l'impossibilità di utilizzare il Sito e fruire dei relativi Servizi.</p>

            <h5 class="card-title">Comunicazione e diffusione dei dati</h5>
            <p class="text-muted">I dati personali sono utilizzati unicamente al fine di eseguire il servizio o la prestazione richiesta e sono comunicati a terzi nei soli casi in cui:<br>
              - ciò è necessario per l'adempimento delle richieste (es. consultazioni di documentazione o risultanze);<br>
              - per obblighi di legge;<br>
              - per procedimenti legali.<br>
              I trattamenti connessi a questo servizio sono curati esclusivamente da personale dell'Ente incaricato del trattamento dei dati.</p>           
            
            <h5 class="card-title">Diritti dell'interessato</h5>
            <p class="text-muted">L'interessato ha diritto di ottenere la conferma dell'esistenza o meno di dati personali che lo riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile.<br><br>
              L'interessato ha diritto di ottenere l'indicazione:<br>
              - dell'origine dei dati personali;<br>
              - delle finalità e modalità del trattamento;<br>
              - della logica applicata in caso di trattamento effettuato con l'ausilio di strumenti elettronici;<br>
              - degli estremi identificativi del titolare, dei responsabili e del rappresentante designato ai sensi dell'articolo 5, comma 2;<br>
              - dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono venirne a conoscenza in qualità di rappresentante designato nel territorio dello Stato, di responsabili o incaricati;<br><br>
              L'interessato ha diritto di ottenere:<br>
              - l'aggiornamento, la rettificazione ovvero, quando vi ha interesse, l'integrazione dei dati;<br>
              - la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, compresi quelli di cui non è necessaria la conservazione;<br>
              - l'attestazione che le operazioni di cui alle lettere a) e b) sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestamente sproporzionato rispetto al diritto tutelato.<br><br>
              L'interessato ha diritto di opporsi, in tutto o in parte:<br>
              - per motivi legittimi al trattamento dei dati personali che lo riguardano, ancorché pertinenti allo scopo della raccolta al trattamento di dati personali che lo riguardano a fini di invio di materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o di comunicazione commerciale;<br>
            </p>           

            <h5 class="card-title">Titolari e responsabili del trattamento</h5>
            <p class="text-muted">Il Titolare del trattamento dei dati è l'ENTE in intestazione (vedasi il logo e la denominazione presente in alto a sinistra in questo sito) al quale ci si potrà rivolgere per esercitare i diritti di cui all'articolo 7 del D.Lgs. cit e/o per conoscere l'elenco aggiornato di tutti i Responsabili del trattamento dei dati.</p>

            <br><br>
            <a href="javascript:window.print()" class="btn btn-soft-primary d-print-none">Stampa questa Pagina</a> 
           
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->