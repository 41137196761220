import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class CommonService {
  constructor() { }

  public apiUrl: string = 'https://open.pmweb.it:44443';

  isLoading: boolean = true;
  private isLoading$ = new Subject<boolean>();

  getIsLoading(): boolean{
    return this.isLoading;
  }
  setIsLoading(value: boolean): void{
    this.isLoading = value;
    this.isLoading$.next(this.isLoading);
  }

  getIsLoadingListener() {
    return this.isLoading$.asObservable();
  }

}
