import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})

/**
 * PAge Privacy Component
 */
export class PrivacyPolicyComponent implements OnInit {

  constructor() { }

  // // inizio pl24
  // codEnte: string | null = '';
  // //collapsed: boolean = false;
  // hasData: boolean = false;
  // datiEnteSub: Subscription = Subscription.EMPTY;
  // imageSource: SafeResourceUrl = '';
  // nomeComune: string = '';
  // // fine pl24

  ngOnInit(): void {

    // alert("TEST");
    // // inizio pl24
    // // Rimane in ascolto su un qualsiasi cambiamento della variabile Subject, ossia quando ritorna la risposta dall'api di recupero info ente
    // this.datiEnteSub = this.enteService.getDatiEnteListener().subscribe((result) => {
    //   if (result) {
    //     this.hasData = true;
    //     this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${result.records[0].logoEnte}`);
    //     this.nomeComune = result.records[0].desEnte;
    //     this.codEnte = this.enteService.getEnte();

    //     alert(this.codEnte);
    //   }
    //   else {
    //     console.log("Errore navbar");
    //   }
    // });
    // // fine pl24
  }

  // // inizio pl24
  // ngOnDestroy() {
  //   this.datiEnteSub.unsubscribe();
  // }
  // // fine pl24

}
