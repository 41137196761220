import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Infrazione, InfrazioneRequest, InfrazioneResponse } from '../models/common';
import { CommonService } from './common.service';

@Injectable({providedIn: 'root'})
export class InfrazioneService {
  constructor(
    private httpClient: HttpClient,
    private commonService: CommonService) { }

  private readonly apiUrl = this.commonService.apiUrl + '/infrazione/';
  private infrazione: Infrazione | undefined;

  setInfrazione(infrazione: Infrazione){
    this.infrazione = infrazione;
  }

  getLocalInfrazione(): Infrazione | undefined{
    return this.infrazione;
  }

  getInfrazione(reqObj: InfrazioneRequest) {
    return this.httpClient.post<InfrazioneResponse>(this.apiUrl + 'read', reqObj);
  }
}
