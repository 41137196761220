import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfrazioneRequest } from 'src/app/pl24/models/common';
import { EnteService } from 'src/app/pl24/services/ente.service';
import { InfrazioneService } from 'src/app/pl24/services/infrazione.service';

@Component({
  selector: 'app-consulta-infrazione',
  templateUrl: './consulta-infrazione.component.html',
  styleUrls: ['./consulta-infrazione.component.css']
})

/***
 * Services Component
 */
export class ConsultaInfrazioneComponent implements OnInit {

  /***
   * Footer bg color set
   */
  footerClass: true;
  footerVariant = 'bg-light';
  
  constructor(
              private modalService: NgbModal,
              private enteService: EnteService,
              private infrazioneService: InfrazioneService,
              private router: Router,
              private formBuilder: UntypedFormBuilder
              ) { }

  infrazioneForm: UntypedFormGroup | undefined;
  isInfrazioneFormValid: boolean = true;
  errorMessage: string = '';
  isLoading = false;
 

  ngOnInit(): void {

    this.infrazioneForm = this.formBuilder.group({
      targa: ["", Validators.required],
      accertamento: ["", Validators.required],
      data: ["", Validators.required],
      password: ["", Validators.required],
    });



    // // Legge il codEnte da URL
    // this.codEnte = this.activatedRoute.snapshot.paramMap.get('codEnte');
    // // Se non è presente il codEnte nell'URL della Homepage è un errore!
    // if (this.codEnte === null) {
    //   // Reindirizzamento verso la pagina NOT-FOUND
    //   this.router.navigateByUrl('/');
    // }
    // else {
    //   // Memorizzazione del codEnte nella localStorage
    //   this.enteService.setEnte(this.codEnte);
    //   // Chiamata API per recupero logo e nome comune
    //   this.enteService.getDatiEnteSubject(this.codEnte);
    // }
  }

  openModal(content) {
    this.modalService.open(content, { size: 'lg', centered: true });
  }

  /**
   * Open modal for show the video
   * @param content content of modal
   */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

  onSubmit() {
    
    if (this.infrazioneForm?.invalid) {
      // Il form NON è valido quindi fa vedere la label di errore
      this.errorMessage = 'Tutti i campi sono obbligatori!';
      this.isInfrazioneFormValid = false;
    }
    else {
      this.isLoading = true;
      this.isInfrazioneFormValid = true;
      const targa = this.infrazioneForm?.get('targa')?.value;
      const accertamento = this.infrazioneForm?.get('accertamento')?.value;
      const data = this.infrazioneForm?.get('data')?.value;
      const password = this.infrazioneForm?.get('password')?.value;
      //console.log(data);
      const reqObj: InfrazioneRequest = {
        codEnte: this.enteService.getEnte(),
        targa: this.infrazioneForm?.get('targa')?.value,
        accertamento: this.infrazioneForm?.get('accertamento')?.value,
        dataInfrazione: this.infrazioneForm?.get('data')?.value,
        password: this.infrazioneForm?.get('password')?.value
      }
      this.infrazioneService.getInfrazione(reqObj).subscribe(result => {
        this.isLoading = false;
        this.infrazioneService.setInfrazione(result.records[0]);
        this.router.navigateByUrl('/infrazione');
      }, err => {
        console.log(err);
        this.isLoading = false;
      });

    }
  }

}
