import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
// import { environment } from 'src/environments/environment';
import { EnteResponse } from '../models/common';
import { EnumLocalStorage } from '../models/enum';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })

export class EnteService {
  constructor(
    private httpClient: HttpClient,
    private commonService: CommonService) { }

  // private readonly apiUrl = `${environment.apiUrl}ente`;
  private readonly apiUrl = this.commonService.apiUrl + '/ente/';

  private datiEnte$ = new Subject<EnteResponse>();
  private ente: string = '';

  setEnte(ente: string) {
    this.ente = ente;
    localStorage.setItem(EnumLocalStorage.CodEnte, this.ente);
  }
  getEnte(): string {
    var returnEnte = '';
    if (this.ente != '') {
      returnEnte = this.ente;
    }
    else {
      // Questa porzione di codice viene eseguita quanto si aggiorna la pagina
      const enteLocal = localStorage.getItem(EnumLocalStorage.CodEnte);
      if( enteLocal != null && enteLocal != ''){
        this.ente = enteLocal;
        returnEnte = this.ente;
        this.getDatiEnteSubject(this.ente);
      }
    }
    return returnEnte;
  }

  getDatiEnteListener() {
    return this.datiEnte$.asObservable();
  }

  getDatiEnteSubject(codEnte: string) {    
    this.httpClient.get<EnteResponse>(this.apiUrl + codEnte).subscribe(result => {
      this.datiEnte$.next(result);
    }, err => {
      this.datiEnte$.next(undefined);
    });
  }
}
