import { Injectable } from '@angular/core';
import { EnteService } from '../services/ente.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private enteService: EnteService) { }
  isAuth(): boolean {
    let result = false;
    const ente = this.enteService.getEnte();
    if (ente != '') {
      result = true;
    }
    return result;
  }
}
