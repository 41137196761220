
<!--end footer-->
<footer class="footer footer-bar">
    <div class="container text-center">
        <div class="row align-items-center">
            <div class="col-sm-8">
                <div class="text-sm-start">
                    <p class="mb-0">
                        <!-- <script type="text/javascript" id="www-widgetapi-script"
                            src="https://www.youtube.com/s/player/5d56cf74/www-widgetapi.vflset/www-widgetapi.js"
                            async=""></script> -->
                        <!-- <script id="iframe_api" src="https://www.youtube.com/iframe_api"></script> -->
                        © {{year}} PL24 è una piattaforma per il cittadino offerta da Italset Srl - p.iva 03890260759.
                    </p>
                </div>
            </div>
            <!--end col-->

            <div class="col-sm-3 mt-2 mt-sm-0 pt-2 pt-sm-0">
                <p class="mb-0"><a routerLink="cookie-policy" class="text-reset">Cookie Policy</a> | <a routerLink="privacy-policy" class="text-reset">Privacy Policy</a></p>
                <!-- <ul class="list-unstyled text-sm-end mb-0 text-foot">
                    <li class="list-inline-item"><a href="javascript:void(0)">Privacy</a></li> -->
                    <!-- <li class="list-inline-item ms-1"><a href="javascript:void(0)"><img
                                src="assets/images/payments/discover.png" class="avatar avatar-ex-sm" title="Discover"
                                alt=""></a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)"><img
                                src="assets/images/payments/master-card.png" class="avatar avatar-ex-sm"
                                title="Master Card" alt=""></a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)"><img
                                src="assets/images/payments/paypal.png" class="avatar avatar-ex-sm" title="Paypal"
                                alt=""></a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)"><img
                                src="assets/images/payments/visa.png" class="avatar avatar-ex-sm" title="Visa"
                                alt=""></a></li> -->
                <!-- </ul> -->
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</footer>
<!--end footer-->